import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubscribeRibbon} from './SubscribeRibbon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';

function HotelContactInfo() {

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
        setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    const { id, title } = useParams();

    const [microData, setMicroData] = useState({
        id: '',
        name: '',
        domain: '',
        p1: '',
        p2: '',
        p3: '',
        place: '',
    });
    useEffect(() => {
        if(id){
            const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?article_id=${id}`;
          console.log('API URL:', apiUrl);
    
          fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
              console.log('API Response:', data);
              setMicroData(data[0]); // Assuming data is an array with a single item
            })
            .catch(error => console.error('API Error:', error));
        }
      }, [id]);

/*
    const [swiperData, setSwiperData] = useState([]);
    
    useEffect(() => {
        if(microData.id){
        const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${microData.id}`;
        console.log('microDataUrl:', microDataUrl);
        // Replace with your API URL
        fetch(microDataUrl)
            .then((response) => response.json())
            .then((data) => setSwiperData(data))
            .catch((error) => console.error('Error fetching data:', error));
        }
    }, [microData.id]);
*/

    const [hotelData, setHotelData] = useState({});
    const { 
        name
        ,short_description
        ,description
        ,hotel_image
        ,feature_images
        ,home_feature_images
        ,image_featured
        ,home_image_featured
        ,thumbnail_image
        ,city
        ,state
        ,neighborhood
        ,nighborhoodcity
        ,star_rating
        ,group
        ,logo_image
        ,location
        ,latitude
        ,longitude
        ,address
        ,zipcode
        ,type
        ,status
        ,interest_type
        ,category_type
        ,budget_type
        ,premium
        ,room
        ,available_date
        ,price
        ,roomtype
        ,available
        ,videos
        ,article
        ,featured
        ,contact_person
        ,contact_number
        ,contact_numbertw
        ,contact_address
        ,contact_person_hotel
        ,contact_number_hotel
        ,Percentage_commission_hotel
        ,special_rate
        ,email_id_hotel
        ,Address_hotel
        ,booking_office_number
        ,mobile_number_book
        ,booking_office_contact_name
        ,bank_details
        ,b2b
        ,convenience_charge
        ,convenience_charge_type
        ,facility
        ,keywords
        ,iskeyword
        ,note1
        ,note2
        ,metadesc
        ,metakey_tariff
        ,metadesc_tariff
        ,metakey_contact
        ,metadesc_contact
        ,metakey_enquiry
        ,metadesc_enquiry
        ,highlights
        ,special_notes
        ,delete_status
    } = hotelData;

    const hotelAPIURL = `${process.env.REACT_APP_API_ENDPOINT}hotel/${id}`;
    console.log("hotelAPIURL", hotelAPIURL);

    useEffect(() => {
        fetch(hotelAPIURL)
        .then(response => response.json())
        .then(data => setHotelData(data))
        .catch(error => console.error(error));
    }, []);  

    let refined_url = name ? name.toLowerCase().replace(/\s+/g, '-') : '';

    const cleanedShortDescription = short_description ? short_description.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') : "";

    const cleanedDescription = description ? description
    .replace(/<p[^>]*>/g, '<p>')
    .replace(/<\/p[^>]*>/g, '</p>')
    .replace(/<(?!\/?(p|br)\b)[^>]+>/g, '')
    .replace(/&nbsp;/g, ' ') : "";


    const [swiperData, setSwiperData] = useState([]);
    useEffect(() => {
        const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=hotel`;
        console.log("imageAPIURLGallery: ", imageAPIURLGallery);
        fetch(imageAPIURLGallery)
        .then(response => response.json())
        .then(data => setSwiperData(data))
        .catch(errorGallery => console.errorGallery(errorGallery));
    }, []);

    
    const [thumbImages, setThumbImages] = useState([]);

    useEffect(() => {
        const imageAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=thumb&site_category=hotel`;
        fetch(imageAPIURL)
        .then(response => response.json())
        .then(data => setThumbImages(data))
        .catch(error => console.error(error));
    }, []);

    const [cityState, setCityState] = useState([]);

    useEffect(() => {
        if (city) {
        const cityStateAPIURL =  `${process.env.REACT_APP_API_ENDPOINT}cities?city_id=${city}`;
        console.log("cityStateAPIURL: ", cityStateAPIURL);
        fetch(cityStateAPIURL)
            .then(response => response.json())
            .then(data => setCityState(data))
            .catch(error => console.error(error));
        }
    }, [city]);


    let oldImage;
    if(hotel_image == ''){
        oldImage = 0;    
    } else{
        oldImage = 1;
    }
    //console.log("oldImage: ", oldImage);

    const [highlightsFetch, setHighlightsFetch] = useState([]);

    useEffect(() => {
        const highlightsUrl = `${process.env.REACT_APP_API_ENDPOINT}highlights_list`;
        fetch(highlightsUrl)
            .then(response => response.json())
            .then(data => setHighlightsFetch(data))
            .catch(error => console.log(error));
        }, []);

    const [checkedHighlights, setCheckedHighlights] = useState([]);

    useEffect(() => {
        if (highlights) {
            const highlightIds = highlights.split(',');
            setCheckedHighlights(highlightIds);
        }
    }, [highlights]);

    const [open, setOpen] = React.useState(false);


    return ( 
        <div>
            <section class="py-10 d-flex items-center bg-light-2">
                <div class="container">
                    <div class="row y-gap-10 items-center justify-between">
                        <div class="col-auto">
                        <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                            <div class="col-auto">
                            <div class="">Home</div>
                            </div>
                            <div class="col-auto">
                            <div class="">&gt;</div>
                            </div>
                            <div class="col-auto">
                            <div class="">Hotels</div>
                            </div>
                            <div class="col-auto">
                            <div class="">&gt;</div>
                            </div>
                            <div class="col-auto">
                            <div class="text-dark-1">{name}</div>
                            </div>
                        </div>
                        </div>

                        <div class="col-auto">
                        {/*<a href="#" class="text-14 text-blue-1 underline">View Hotels Involving Dwarka</a>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-40">
                <div class="container">
                <div class="row y-gap-20 justify-between items-end">
                    <div class="col-auto">
                    <div class="row x-gap-20  items-center">
                        <div class="col-auto">
                        <h1 class="text-30 sm:text-25 fw-600" style={{maxWidth:750,}}>{name}</h1>
                        </div>
                    </div>

                    <div class="row x-gap-20 y-gap-20 items-center">
                        <div class="col-auto">
                        <div class="d-flex items-center text-15 text-light-1">
                            {cityState.length > 0 && `${cityState[0].city}, ${cityState[0].state}`} (<b>{star_rating} Star Hotel</b>)
                        </div>
                        </div>
                    </div>
                    </div>

                    <div class="col-auto">
                    <div class="row x-gap-15 y-gap-15 items-center">
                        <div class="col-auto">
                        <div class="text-14">
                        {price !== null && price !== '0' ? (
                            <>
                            Price Starts From{' '}
                            <span className="text-22 text-dark-1 fw-500">₹{price}</span>
                            </>
                        ) : (
                            <a href={`https://www.blessingsonthenet.com/hotel/${refined_url}/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                Book Now
                                <div class="icon-arrow-top-right ml-15"></div>
                            </a>
                        )}
                        </div>
                        </div>
                        
                        {price !== null && price !== '0' ? (
                        <div class="col-auto">
                        <a href="#book-now" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                            Book Now <div class="icon-arrow-top-right ml-15"></div>
                        </a>
                        </div>
                        ) : ''}


                    </div>
                    </div>
                </div>

                </div>
            </section>

            <section class="pt-30">
                <div class="container">
                <div class="row y-gap-30">
                    <div class="col-xl-8">
                        <div class="row y-gap-40 dark_text">

                            <div className="swiper-wrapper">
                                <Swiper
                                spaceBetween={30}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={1}
                                loop={true}
                                modules={[Autoplay]}
                                className="mySwiper"
                                >
                                {swiperData.map((item) => (
                                    <SwiperSlide key={item.id}>
                                    <img style={{width:'100%'}} src={`https://api.blessingsonthenet.com/uploads/hotels/${item.hotel_image}`} alt={`Slide ${item.id}`} />
                                    </SwiperSlide>
                                ))}
                                </Swiper>
                            </div>

                            <div class="col-12 mb-80">
                                <h3 class="text-22 fw-500 pt-40 border-top-light">Address</h3><br />
                                {/*<div dangerouslySetInnerHTML={{ __html: cleanedDescription }} />*/}
                                <div dangerouslySetInnerHTML={{ __html: address }} />               
                            </div>

                        </div>
                    </div>

                    <div class="col-xl-4">
                        
                        <div class="ml-50 lg:ml-0" style={{marginBottom:'20px'}}>
                            <div class="px-30 pt-20 pb-20 border-light rounded-4 shadow-4" id="book-now">
                                <div class="sidebar__item" style={{borderTop:'none'}}>
                                    <h5 class="text-20 fw-500 mb-10">Quick Links</h5>
                                    <div class="sidebar-checkbox">
                                        <table class="table-4 w-1/1 blessings_categories">
                                            <tbody>
                                                <tr>
                                                    <td>    
                                                        <a href={`/${domainName === 'shirdisaitemple' ? 'shirdi-hotel' : 'hotel'}/id/${id}/${refined_url}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Overview
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>    
                                                        <a href={`/${domainName === 'shirdisaitemple' ? 'shirdi-hotel' : 'hotel'}/tariff/${id}/${refined_url}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Current Tariff/Packages
                                                        </a>
                                                    </td>
                                                </tr>
                                                {/*
                                                <tr>
                                                    <td>    
                                                        <a href={`https://www.blessingsonthenet.com/hotels/accommodation-enquiry/${refined_url}/${id}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Reservation Enquiry
                                                        </a>
                                                    </td>
                                                </tr>
                                                */}
                                                <tr>
                                                    <td>    
                                                        <a className="text-blue-1" href={`/${domainName === 'shirdisaitemple' ? 'shirdi-hotel' : 'hotel'}/contact-info/${id}/${refined_url}`}>
                                                            <img src={`/assets/img/general/categories-arrow.png`} />
                                                            Contact Us
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ml-50 lg:ml-0">

                            <div class="px-30 pt-10 pb-20 border-light rounded-4 shadow-4" id="book-now">
                                <div class="d-flex items-center justify-between">
                                    <div class="">
                                    <span class="text-20 fw-500">Book Now</span>
                                    </div>
                                </div>
                                
                                <div className="text-center">
                                    <br />
                                    <h6 style={{color:'#FF0000'}}>WhatsApp for Immediate Response and Customization</h6>
                                    <br />
                                    <a href="https://wa.me/918452040404">+91 8452 040 404</a><br />
                                    <a href="https://wa.me/919224444455">+91 9224 444 455</a><br />
                                    <a href="https://wa.me/919987404440">+91 9987 404 440</a>
                                    <br /><br />
                                    <h6 style={{color:'#FF0000'}}>Send Us Email</h6>
                                    <a href="mailto:travel@blessingsonthenet.com">travel@blessingsonthenet.com</a><br /><br />
                                    
                                    <a href={`https://www.blessingsonthenet.com/hotel/${refined_url}/${id}`} class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                                        Book Now
                                        <div class="icon-arrow-top-right ml-15"></div>
                                    </a><br />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                </div>
            </section>
            <SubscribeRibbon />
        </div>
    );
}

export default HotelContactInfo;