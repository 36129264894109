import React, { useEffect, useState } from 'react';

export function SubscribeRibbon() {

  const [email, setEmail] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubscriptionSubmit = async (e) => {
    e.preventDefault();

    // Check for honeypot field
    if (e.target.subscriber_user.value) {
      // It's a bot, ignore the submission
      return;
    }

    if (!email) {
      // Email field is required
      alert('Email is required');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}email_subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setIsFormSubmitted(true);
      } else {
        alert('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <section className="layout-pt-md layout-pb-md bg-dark-2">
      <div className="container">
        <div className="row y-gap-30 justify-between items-center">
          <div className="col-auto">
            <div className="row y-gap-20  flex-wrap items-center">
              <div className="col-auto">
                <div className="icon-newsletter text-60 sm:text-40 text-white"></div>
              </div>

              <div className="col-auto">
                <h4 className="text-26 text-white fw-600">Your Travel Journey Starts Here</h4>
                <div className="text-white">Sign up and we'll send the best deals to you</div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="single-field -w-410 d-flex x-gap-10 y-gap-20">
              {isFormSubmitted ? (
                <p style={{color:'green'}}>Thanks for Subscribing!</p>
              ) : (
                <form onSubmit={handleSubscriptionSubmit} style={{display:'flex'}}>
                  <div>
                    <input
                      className="bg-white h-60"
                      type="email"
                      name="subscriber_email"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                    {/* Honeypot field */}
                    <input type="text" name="subscriber_user" style={{ display: 'none' }} />
                    <div style={{marginLeft:'10px'}}>
                      <button 
                      name="subscription_button"
                      className="button -md h-60 bg-blue-1 text-white"
                      type="submit">Subscribe</button>
                    </div>
                    
                  
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
