import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import { GroupHome } from './GroupHome';
import { HomeIndividual } from './HomeIndividual';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function Home() {
  // Domain details Fetch
  const [domainName, setDomainName] = useState('');

  // Fetching the domain name
  useEffect(() => {
    setDomainName(getCleanedDomainName());
  }, []);

  console.log('Cleaned Domain Name:', domainName);

  const [micrositeData, setMicrositeData] = useState([]);

  useEffect(() => {
    // Fetch microsite data from the API
    const groupCheck = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/all?cat=group`;
    fetch(groupCheck)
      .then(response => response.json())
      .then(data => setMicrositeData(data))
      .catch(error => console.error('Error fetching microsite data:', error));
  }, []);

  // Check if the domain matches any item in micrositeData
  const isGroupMicrosite = micrositeData.some(item => domainName.includes(item.domain));


  
  return (
    <>
      {isGroupMicrosite ? (
        <GroupHome />
      ) : (
        <HomeIndividual />
      )}
    </>
  );
}

export default Home;