import React, { useEffect, useState } from 'react';
//import './assets/css/body.css'; // Import CSS from the theme
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';
import { SubscribeRibbon} from './SubscribeRibbon';
import {Helmet} from "react-helmet";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function GroupInnerArticle() {
  // Domain details Fetch
  const [domainName, setDomainName] = useState('');

  // Fetching the domain name
  useEffect(() => {
    setDomainName(getCleanedDomainName());
  }, []);

  console.log('Cleaned Domain Name:', domainName);

  const { id, title, templeId } = useParams();

  const [microData, setMicroData] = useState({
    id: '',
    title: '',
    content: '',
  });

  useEffect(() => {
    if(id){
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data?inner_article_id=${id}`;
      console.log('API URL:', apiUrl);

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          console.log('API Response:', data);
          setMicroData(data[0]); // Assuming data is an array with a single item
        })
        .catch(error => console.error('API Error:', error));
    }
  }, [id]);

  //console.log('Name:', microData.name);
  //console.log('Domain:', microData.domain);


  const [swiperData, setSwiperData] = useState([]);

  useEffect(() => {
    if(id){
      const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${templeId}`;
      console.log('microDataUrl:', microDataUrl);
      // Replace with your API URL
      fetch(microDataUrl)
        .then((response) => response.json())
        .then((data) => setSwiperData(data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [id]);


  function stripHtmlTags(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  // Articles fetch starts
  const [p1, setP1] = useState({
    title: '',
    content: '',
  });
  

  useEffect(() => {
    if(id){
      const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}temple_articles/${id}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const firstItem = data[0];

          if (firstItem && firstItem.title && firstItem.content) {
            setP1({
              title: firstItem.title,
              content: firstItem.content,
            });
          } else {
            console.error('Temple article data is incomplete.');
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [id]);

  let p1Refined = p1.title ? p1.title.toLowerCase().replace(/\s+/g, '-') : '';
  let p1URL = '';
  if(domainName == 'shirdisaitemple'){
    p1URL = `https://www.${domainName}.com/shirdi-article/id/${id}/${p1Refined}/`;
  }
  else{
    p1URL = `https://www.${domainName}.com/article/id/${id}/${p1Refined}/`;
  }

  const [tourPackages, setTourPackages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    if(templeId){
      const tourPackagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_tour_listings/${templeId}`;
      console.log("tourPackagesURL: ", tourPackagesURL);
      fetch(tourPackagesURL)
      .then(response => response.json())
      .then(data => setTourPackages(data))
      .catch(error => console.error(error));

    // Fetch gallery images
    const galleryImagesURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_temple_galleries/${templeId}`;
    fetch(galleryImagesURL)
      .then(response => response.json())
      .then(data => setGalleryImages(data))
      .catch(error => console.error(error));

    }
  }, [templeId]);

  
  const [articleListings, setArticleListings] = useState([]);

  useEffect(() => {
    if(templeId){
      const articleListingsURL = `${process.env.REACT_APP_API_ENDPOINT}microsite_article_listings/${templeId}?all=1`;
      console.log("articleListingsURL: ", articleListingsURL);
      fetch(articleListingsURL)
      .then(response => response.json())
      .then(data => setArticleListings(data))
      .catch(error => console.error(error));
    }
  }, [templeId]);

  
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{microData.title}</title>
    </Helmet>
    {/* Slider section */}
    <section className="z-5">
      <div className="swiper-wrapper">
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {swiperData.map((item) => (
            <SwiperSlide key={item.id}>
              <img style={{width:'100%'}} src={
              item.image.includes('aim_')
              ? `https://blessingsonthenet.com/img/slideshow/${item.image}`
              : `https://api.blessingsonthenet.com/uploads/microsite/${item.image}`
            } alt={`Slide ${item.id}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>

    {/* The 3 articles section */}
    <section class="section-bg">
      <div class="section-bg__item -mx-20"></div>

      <div data-anim-wrap class="container">

        <div id="overview" class="col-12 mt-20">
            <h1 class="sectionTitle__title mb-30">{p1.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: p1.content }} />
            {/*<a href="#" class="d-block text-14 text-blue-1 fw-500 underline mt-10">Show More</a>*/}
        </div>
        
      </div>
    </section>

    {/* Holidays Section */}
    <section className="layout-pt-lg layout-pb-md">
      <div className="container">
        <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end is-in-view">
          <div className="col-auto">
            <div className="sectionTitle -md">
              <h2 className="sectionTitle__title">Related Tour Packages</h2>
            </div>
          </div>

          <div className="col-auto md:d-none">

            <a href="https://www.blessingsonthenet.com/travels/travel/page/1" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
              View All Holidays <div className="icon-arrow-top-right ml-15"></div>
            </a>

          </div>
        </div>

        <div className="relative pt-40 sm:pt-20 js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden is-in-view">
          <div className="swiper-wrapper">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              slidesPerView={3}
              loop={true}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
            {tourPackages.map((hpackage, index) => (
              <SwiperSlide>
                <div className="swiper-slide swiper-slide-visible swiper-slide-active" key={hpackage.id} 
                style={{marginRight: '30px'}}>
                  <a href={`/${domainName === 'shirdisaitemple' ? 'shirdi-article' : 'article'}/id/${hpackage.id}/temple/${templeId}/${hpackage.title.toLowerCase().replace(/\s+/g, '-')}`} className="citiesCard -type-1 d-block rounded-4 ">
                    <div className="citiesCard__image ratio ratio-2:1">
                      {galleryImages[index] && (
                        <img src={`https://blessingsonthenet.com/img/gallery/${galleryImages[index].thumb}`} alt={hpackage.title} />
                      )}
                      {/*
                      <img
                        src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        data-src={hpackage.thumb_new ? `https://api.blessingsonthenet.com/uploads/${hpackage.thumb_new.replace(/ /g, '%20')}` : `https://www.blessingsonthenet.com/img/uploads/tour/thumbs/${hpackage.thumb}`}
                        alt="image"
                      />
                      */}
                    </div>

                    <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                      <div className="citiesCard__bg"></div>

                      {/*
                      <div className="citiesCard__top">
                        <div className="text-14 text-white">{hpackage.nights} Night {hpackage.days} Days</div>
                      </div>
                      */}

                      <div className="citiesCard__bottom">
                        <h4 className="text-16 md:text-20 lh-13 text-white mb-10">{hpackage.title}</h4>
                        {/*<p className="starts_from">Starts from ₹{hpackage.price}/-</p>*/}
                        <button className="button col-12 h-40 -blue-1 bg-white text-dark-1">View Details</button>
                      </div>
                    </div>
                  </a>

                </div>
              </SwiperSlide>
            ))}
            </Swiper>
          </div>

          <div className="row pt-20 d-none md:d-block">
            <div className="col-auto">
              <div className="d-inline-block">

                <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                  View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    {/* Articles Section */}
    <section class="layout-pt-lg layout-pb-md relative" id="secondSection">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row y-gap-20 justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Articles</h2>
              {/*<p class=" sectionTitle__text mt-5 sm:mt-0">Here are list of nearby places that you might be intersted in</p>*/}
            </div>
          </div>
        </div>

        <div class="row x-gap-10 y-gap-10 pt-40 sm:pt-20">

          {articleListings.map((temple) => (
            <div key={temple.id} data-anim-child="slide-up delay-4" class="col-xl-4 col-lg-3 col-6 is-in-view">
              <a href={`/${domainName === 'shirdisaitemple' ? 'shirdi-article' : 'article'}/id/${temple.id}/temple/${templeId}/${temple.title.toLowerCase().replace(/\s+/g, '-')}`} class="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4">
                <div class="ml-10 sm:ml-0 sm:mt-10">
                  <h4 title={temple.title} class="text-16 fw-500"> {temple.title.length > 40 ? `${temple.title.substring(0, 40)}...` : temple.title}</h4>
                </div>
              </a>
            </div>
          ))}

        </div>
      </div>
    </section>

    <SubscribeRibbon />

    </>
  );
}

export default GroupInnerArticle;